<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- Modal -->
    <div
      class="modal fade"
      id="deleteVideo"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Delete Content</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Are you sure you want to do this ?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              @click="deleteContent"
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal end -->
    <div class="card" v-if="loading">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center">
          <div class="spinner-border m-5" role="status">
            <span class="visually-hidden"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-if="!loading && !success">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center my-5">
          <p>No Resource Found. Try Again</p>
        </div>
      </div>
    </div>

    <div class="row " v-if="!loading && success">
      <div class="col-md-12 mb-4">
        <h4 class="card-title my-3 float-right">
          <router-link
            :to="`/entity/${$route.params.id}/courses`"
            class="btn btn-success btn-sm"
            >Courses</router-link
          >
        </h4>
      </div>
    </div>
    <div
      class="container card content_margin_bottom pt-4"
      v-if="!loading && success"
    >
      <div class="table-wrapper">
        <table class="table table-borderless">
          <thead>
            <tr>
              <td><b>Test</b></td>
              <td>
                <router-link
                  v-if="isTest !== 'test'"
                  :to="
                    `/entity/${this.$route.params.id}/course/${course.id}/questions`
                  "
                  class="btn btn-success btn-sm"
                >
                  <span>
                    Add Examination Test
                  </span>
                </router-link>
                <router-link
                  v-else
                  :to="
                    `/entity/${this.$route.params.id}/course/${course.id}/test/${testID}`
                  "
                  class="btn btn-success btn-sm"
                >
                  <span>
                    View Examination Test
                  </span></router-link
                >
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-if="course.individuals_with_cert > 0">
              <td><b>Individuals</b></td>
              <td>
                <router-link
                  :to="
                    `/entity/${entityId}/course/${courseId}/passed-tests/${testID}`
                  "
                >
                  <p class="btn btn-success btn-sm m-2 p-2">
                    Passed Test
                  </p>
                </router-link>
              </td>
            </tr>
            <tr v-if="course.individuals_with_cert > 0">
              <td><b>Individuals</b></td>
              <td>
                <router-link
                  :to="`/entity/${entityId}/courses/${courseId}/e-cert-users`"
                >
                  <p class="btn btn-success btn-sm m-2 p-2">
                    E-Cert Users
                  </p>
                </router-link>
              </td>
            </tr>
            <tr v-if="course.enrolled > 0">
              <td><b>Individuals</b></td>
              <td>
                <router-link
                  :to="`/entity/${entityId}/course/${courseId}/enrolled-users`"
                >
                  <p class="btn btn-success btn-sm m-2 p-2">
                    Enrolled Users
                  </p>
                </router-link>
              </td>
            </tr>
            <tr>
              <td scope="col"><b>Course Title</b></td>
              <td scope="col">{{ course.title }}</td>
            </tr>
            <tr>
              <td><b>Course Overview</b></td>
              <td>{{ course.overview }}</td>
            </tr>
            <tr>
              <td><b>Number of Enrolled</b></td>
              <td>{{ course.enrolled }}</td>
            </tr>
            <tr>
              <td><b>Individuals with Certificate</b></td>
              <td>{{ course.individuals_with_cert }}</td>
            </tr>
            <tr>
              <td><b>Course Catalogue</b></td>
              <td>{{ course.show_in_catalogue }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <table v-if="isArticle" class="table table-borderless">
        <thead>
          <tr class="d-flex justify-content-center">
            <td>
              <i>
                Article Content
              </i>
            </td>
          </tr>
        </thead>
        <tbody>
          <div
            v-for="article in articleTest"
            :key="article.id"
            id="bottom_border"
            class="text-left m-4 d-flex flex-column justify-content-between"
          >
            <tr class="text-left m-4 d-flex justify-content-between">
              <td><b>Unit Name</b></td>
              <td>{{ article.content.unit_name }}</td>
            </tr>
            <tr class="text-left m-4 d-flex justify-content-between">
              <td><b>Description</b></td>
              <td>{{ article.content.description }}</td>
            </tr>
            <tr class="text-left m-4 d-flex justify-content-between">
              <td><b>Active</b></td>
              <td>{{ article.content.active }}</td>
            </tr>
            <tr class="text-left m-4 d-flex justify-content-between">
              <td><b>Video Url</b></td>
              <td>{{ article.content.url }}</td>
            </tr>
            <tr>
              <p></p>
              <div class="d-flex justify-content-center">
                <router-link
                  :to="
                    `/entity/${entityId}/course/${courseId}/content/${article.id}/edit`
                  "
                >
                  <p class="btn btn-success btn-sm m-2 p-2">
                    Edit Article
                  </p>
                </router-link>
                <button
                  class="btn btn-danger btn-sm p-2 m-2"
                  data-toggle="modal"
                  data-target="#deleteVideo"
                  @click="getKeyAndId(article.id)"
                >
                  Delete Article
                </button>
              </div>
            </tr>
          </div>
        </tbody>
      </table>
      <table v-if="isFile" class="table table-borderless">
        <thead>
          <tr class="d-flex justify-content-center">
            <td>
              <i>
                File Content
              </i>
            </td>
          </tr>
        </thead>
        <tbody>
          <div
            v-for="file in fileTest"
            :key="file.id"
            id="bottom_border"
            class="text-left m-4 d-flex flex-column justify-content-between"
          >
            <tr class="text-left m-4 d-flex justify-content-between">
              <td><b>Unit Name</b></td>
              <td>{{ file.content.unit_name }}</td>
            </tr>
            <tr class="text-left m-4 d-flex justify-content-between">
              <td><b>Description</b></td>
              <td>{{ file.content.description }}</td>
            </tr>
            <tr class="text-left m-4 d-flex justify-content-between">
              <td><b>Active</b></td>
              <td>{{ file.content.active }}</td>
            </tr>
            <tr class="text-left m-4 d-flex justify-content-between">
              <td><b>File Name</b></td>
              <td>{{ file.content.file }}</td>
            </tr>
            <tr>
              <p></p>
              <div class="d-flex justify-content-center">
                <router-link
                  :to="
                    `/entity/${entityId}/course/${courseId}/content/${file.id}/edit`
                  "
                >
                  <p class="btn btn-success btn-sm m-2 p-2">
                    Edit File
                  </p>
                </router-link>
                <button
                  class="btn btn-danger btn-sm m-2 p-2"
                  data-toggle="modal"
                  data-target="#deleteVideo"
                  @click="getKeyAndId(file.id)"
                >
                  Delete File
                </button>
              </div>
            </tr>
          </div>
        </tbody>
      </table>
      <table v-if="isVideo" class="table table-borderless">
        <thead>
          <tr class="d-flex justify-content-center">
            <td>
              <i>
                Video Content
              </i>
            </td>
          </tr>
        </thead>
        <tbody>
          <div
            v-for="video in videoTest"
            :key="video.id"
            id="bottom_border"
            class="text-left m-4 d-flex flex-column justify-content-between"
          >
            <tr class="text-left m-4 d-flex justify-content-between">
              <td><b>Unit Name</b></td>
              <td>{{ video.content.unit_name }}</td>
            </tr>
            <tr class="text-left m-4 d-flex justify-content-between">
              <td><b>Description</b></td>
              <td>{{ video.content.description }}</td>
            </tr>
            <tr class="text-left m-4 d-flex justify-content-between">
              <td><b>Active</b></td>
              <td>{{ video.content.active }}</td>
            </tr>
            <tr class="text-left m-4 d-flex justify-content-between">
              <td><b>Video Url</b></td>
              <td>{{ video.content.url }}</td>
            </tr>
            <tr>
              <p></p>
              <div class="d-flex justify-content-center">
                <router-link
                  :to="
                    `/entity/${entityId}/course/${courseId}/content/${video.id}/edit`
                  "
                >
                  <p class="btn btn-success btn-sm m-2 p-2">
                    Edit Video
                  </p>
                </router-link>
                <button
                  class="btn btn-danger btn-sm m-2 p-2"
                  data-toggle="modal"
                  data-target="#deleteVideo"
                  @click="getKeyAndId(video.id)"
                >
                  Delete Video
                </button>
              </div>
            </tr>
          </div>
        </tbody>
      </table>
      <div class="d-flex justify-content-end">
        <router-link
          class="btn btn-primary btn-sm m-2 p-2"
          :to="`/entity/${this.entityId}/course/${courseId}/content/add`"
          >Add Content</router-link
        ><router-link
          :to="`/entity/${this.entityId}/course/${courseId}/edit`"
          class="btn btn-success btn-sm m-2 p-2"
        >
          Edit Course
        </router-link>
      </div>
    </div>
  </Layout>
</template>

<script>
/* eslint-disable no-unused-vars */
import toasterMixin from "@/mixins/toasterMixin.js";
import Layout from "../layout/index.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import store from "@/state/store";

export default {
  name: "Entity Course Details",
  components: {
    Layout,
    PageHeader,
  },
  page: {
    title: "Entity Course Details",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Entity Course Details",
      items: [
        {
          text: "Entity Admin",
        },
        {
          text: "Entity Course Details",
          active: true,
        },
      ],
      course: {},
      video: "",
      isTest: null,
      isFile: null,
      isVideo: null,
      isArticle: null,
      fileTest: [],
      deleteId: null,
      videoTest: [],
      articleTest: [],
      testID: null,
      courseId: this.$route.params.courseId,
      entityId: this.$route.params.id,
      loading: false,
      success: false,
    };
  },
  methods: {
    getKeyAndId(id) {
      this.deleteId = id;
    },

    deleteContent() {
      store
        .dispatch("contents/deleteContent", this.deleteId)
        .then((response) => {
          if (response) {
            this.toast({
              message: "Content Deleted.",
              type: "success",
            });
            this.fetchData();
          }
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data == undefined) {
            this.toast({
              message:
                "Network error. Check your network connection and try again.",
              type: "error",
            });
          }
        });
    },
    fetchData() {
      this.loading = true;
      store
        .dispatch("course/fetchCourses", this.$route.params.id)
        .then((response) => {
          const res = response.data.course;
          const course = res.find((course) => course.id == this.courseId);

          const testType = course.contents;
          console.log(course);

          if (course.test_assesment != null) {
            const testID = course.test_assesment.id;
            this.testID = testID;
            this.isTest = "test";
          }

          for (let i = 0; i < testType.length; i++) {
            const model = testType[i].content_type.model;
            if (model === "video") {
              const videos = testType[i];
              this.videoTest.push(videos);
              this.isVideo = "video";
              console.log("video");
            } else if (model === "file") {
              const articles = testType[i];
              this.fileTest.push(articles);
              this.isFile = "file";
              console.log("files");
            } else if (model === "article") {
              const files = testType[i];
              this.articleTest.push(files);
              this.isArticle = "article";
              console.log("articles");
            }
          }

          this.course = course;
          this.loading = false;
          this.success = true;
        })
        .catch(() => {
          this.loading = false;
          this.success = false;
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped>
b {
  font-weight: bolder;
}
.table-wrapper {
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.247);
}
</style>
